import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
    Route,
    BrowserRouter as Router,
    Switch
} from "react-router-dom";
import { Book } from "./component/landing/sections/Book";
import { Page404 } from './component/page404/page404';
import { PublicRoute } from "./component/routes/publicRoute";
import { ScrollToTop } from "./component/scroll/Scroll";
import { Tracking } from "./component/track/track";
import './css/animate.css';
import './css/style.css';
import { authentication } from "./service/auth.service";
import * as serviceWorker from './serviceWorker';

export default function App() {
    const [passenger] = useState<any>(authentication.getPassengerUser() ? true : false);
    const [corporate] = useState<any>(authentication.getCorporateUser() ? true : false);

    return (
        <div className="appWrapper">
            <Router>
                <>
                    <ScrollToTop />
                    <Switch>
                        <PublicRoute exact path='/' component={Book} isCorporate={corporate} isPassenger={passenger} />
                        <Route exact path='/track/:code' component={Tracking} />
                        <Route exact path='*' render={(data)=>(<Page404 {...data} isCorporate={corporate} isPassenger={passenger}/>)} />
                    </Switch>
                </>
            </Router>
        </div>
    );
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

serviceWorker.unregister();
