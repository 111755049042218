import React from 'react';
export function Page404(props:any) {
    return (
        <>
            <div className="about-section ">
                <h2 className="text-center">
                    404 Page Not Found !
                </h2>
            </div>
        </>
    )
} 