import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import car from '../../img/car.svg';
import { ApiService } from '../../service/api.service';
import { Booking, BookingStatus } from '../entities/booking.entity';
import { Driver } from '../entities/driver.entity';
import { Vehicle } from '../entities/vehicle.entity';
import { getStorageUrl } from '../util/file.util';

const MAP_LIBS: Libraries = ['places'];

interface Gps {
    latitude: number,
    longitude: number,
    heading: number,
}
interface location {
    lat: number,
    lng: number
}

export const Tracking = () => {
    let { code }: any = useParams();
    const [map, setMap] = useState<google.maps.Map>()
    const [center, setCenter] = useState<google.maps.LatLng>();
    const [pickupPlace, setPickupPlace] = useState<location>();
    const [dropoffPlace, setDropoffPlace] = useState<location>();
    const [zoom, setZoom] = useState(10);
    const [booking, setBooking] = useState<Booking>();
    const [driverGps, setDriverGps] = useState<Gps>();
    const [point, setPoint] = useState<google.maps.Point>();
    const [size, setSize] = useState<google.maps.Size>();
    const [imgSrc, setImgSrc] = useState<string>();
    const history = useHistory();
    const [count, setCount] = useState<number>(0);
    const [trackLine, setTrackLine] = useState<any>([]);

    const getProfilePic = (driver: Driver): string => {
        if (driver?.picFile) {
            return `${getStorageUrl()}/driver/${driver.id}/pic/${driver.picFile?.id
                }/admin_single.${driver.picFile?.extension}`;
        }
        return '';
    };

    const onLoad = React.useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
        setTimeout(() => {
            // Sydney
            const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
            const DEFAULT_POINT = new google.maps.Point(32, 32);
            const DEFAULT_SIZE = new google.maps.Size(64, 64);
            setCenter(DEFAULT_CENTER);
            setPoint(DEFAULT_POINT)
            setSize(DEFAULT_SIZE)
            setCenter(DEFAULT_CENTER);
            setZoom(5);
            refresh();
        }, 100);

    }, [])

    useEffect(() => {
        const intervalId = setInterval(async () => {
            //assign interval to a variable to clear it.
            if (count < 6) {
                if (booking?.status === BookingStatus.Dropoff || booking?.status === BookingStatus.Pickup || booking?.status === BookingStatus.AwaitingAccept || booking?.status === BookingStatus.DriverEnroute || booking?.status === BookingStatus.DestinationEnroute || booking?.status === BookingStatus.Arrived) {

                    if (booking?.status === BookingStatus.Dropoff || booking?.status === BookingStatus.Pickup || booking?.status === BookingStatus.AwaitingAccept) {
                        setCount(count + 1)
                    }
                    else {
                        setCount(0)
                    }
                    refresh();
                }
            } else {
                booking && await cancelBooking(booking);
                alert("Sorry, no driver was available in your area")
                //             // setDriverStatus(true)
                history.push('/')
            }
        }, 10000)

        return () => clearInterval(intervalId); //This is important
    })

    const gpslatest = async (booking: Booking) => {
        try {
            const { data } = await ApiService.get(`gps/${(booking?.session?.vehicle as Vehicle)?.plate}`)
            const response = await ApiService.get(`gps/gpsTrack?booking=${booking?.id}`)

            if (response.data) {
                Promise.all(response.data.map((item: any) => {
                    let lat = item.latitude
                    let lng = item.longitude
                    setTrackLine((item: any) => { return [...item, { lat, lng }] })
                }))
                setImgSrc(getProfilePic(data.driver));
                setDriverGps(data?.gps);
                const DEFAULT_CENTER = new google.maps.LatLng(data?.gps.latitude, data?.gps?.longitude);
                setCenter(DEFAULT_CENTER);
                setZoom(18)
            }
        } catch (err) {
            console.error("error in gpslatest", err);
        }
    }

    const refresh = async () => {
        const { data } = await ApiService.get(`booking/${code}`)

        setBooking(data.booking);
        setPickupPlace({ lat: data?.pickupAddress?.lat, lng: data?.pickupAddress?.lng })
        setDropoffPlace({ lat: data?.dropOffAddress?.lat, lng: data?.dropOffAddress?.lng })
        setImgSrc(getProfilePic(data?.session?.driver));

        if (data?.booking.status === BookingStatus.DriverEnroute || data?.booking.status === BookingStatus.DestinationEnroute || data?.booking.status === BookingStatus.Arrived) {
            gpslatest(data.booking)
        } else {
            const DEFAULT_CENTER = new google.maps.LatLng(data?.pickupAddress?.lat, data?.pickupAddress?.lng);
            setCenter(DEFAULT_CENTER);
            setZoom(15)

        }
    }

    const cancelBooking = async (booking: Booking) => {
        try {
            await ApiService.patch('booking/passenger', { id: booking.id });
            alert("booking has been cancelled")
            history.push('/');
        } catch (error) {
            console.error("Error While cancelling:", error)
        }
    }

    const onUnmount = React.useCallback(() => {
        setMap(undefined)
    }, []);

    const getStatusText = () => {
        if (!booking) {
            return;
        }

        let message = '';
        let heading = '';

        if (booking.status === BookingStatus.DriverEnroute) {
            message = 'Please wait';
            heading = 'Driver is on its way';
        } else if (booking.status === BookingStatus.Arrived) {
            message = 'Thanks for being patient';
            heading = 'Destination has arrived';
        } else if (booking.status === BookingStatus.DestinationEnroute) {
            message = 'This ride has began';
            heading = 'Enroute to destination';
        } else if (booking.status === BookingStatus.PassengerCancelled) {
            message = 'This ride has been cancelled by passenger';
            heading = 'Ride Cancelled';
        } else if (booking.status === BookingStatus.DriverCancelled) {
            message = 'This ride has been cancelled by the driver';
            heading = 'Ride cancelled';
        } else if (booking.status === BookingStatus.Abandoned) {
            message = 'This ride was abandoned';
            heading = 'Ride abandoned';
        } else if (booking.status === BookingStatus.Completed) {
            message = 'This ride has been completed';
            heading = 'Thansk for riding with us';
        } else if (booking.status === BookingStatus.NotAccepted) {
            message = 'This ride was not accepted';
            heading = 'No driver found in your area';
        } else if (booking.status === BookingStatus.AwaitingAccept) {
            message = 'Waiting for a driver to accept the ride';
            heading = 'Awaiting Acceptance';
        }

        return (
            <>
                <p>{message}</p>
                <h2>{heading}</h2>
            </>
        );
    }

    return (
        <>
            <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`} libraries={MAP_LIBS}>
                <div className="section-yellow home-section">
                    {(booking?.status === BookingStatus.Dropoff || booking?.status === BookingStatus.Pickup || booking?.status === BookingStatus.AwaitingAccept) ?
                        (
                            <div style={{ textAlign: 'center', fontSize: 50, padding: 50 }}>
                                <h3>Waiting for Driver...</h3>
                                <button className="register-submit" onClick={() => cancelBooking(booking)}>Cancel Booking</button>
                            </div>
                        ) : (
                            <div className="containerTaxi">
                                <div className="box" style={{ display: "block" }}>
                                    <div className='box1 driverInfo'>
                                        <div className="gallery__img">
                                            <img src={imgSrc} className="driverImage" width='100%'></img>
                                        </div>

                                        <div className="flexBox">
                                            <h5>{(booking?.session?.driver as Driver)?.firstName} {(booking?.session?.driver as Driver)?.lastName}</h5>
                                            <h5>{(booking?.session?.driver as Driver)?.phone}</h5>
                                            <p>{(booking?.session?.vehicle as Vehicle)?.year} {(booking?.session?.vehicle as Vehicle)?.make} {(booking?.session?.vehicle as Vehicle)?.model}</p>
                                            <p><strong>Vehicle Color:</strong> {(booking?.session?.vehicle as Vehicle)?.color}</p>
                                            <p><strong>Vehicle Plate :</strong> {(booking?.session?.vehicle as Vehicle)?.plate}</p>
                                            <p><strong>Booking Code:</strong> {booking?.code}</p>
                                            <p><strong>PickUp Address: </strong>{booking?.pickupAddress.text}</p>
                                            <p><strong>DropOff Address:</strong> {booking?.dropOffAddress.text}</p>
                                        </div>
                                    </div>

                                    <div className='box1' style={{ marginTop: "30px", padding: "30px" }}>
                                        <div>
                                            {getStatusText()}
                                            <p>SUPPORT: +44 742 411 8933</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="box box2 borderStyle">
                                    <GoogleMap
                                        mapContainerStyle={{ width: '100%', height: 600 }}
                                        center={center}
                                        zoom={zoom}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                    >
                                        {trackLine && (
                                            <Polyline path={trackLine}
                                                options={{
                                                    strokeColor: "blue",
                                                    strokeOpacity: 0.75,
                                                    strokeWeight: 5,

                                                }}
                                            />
                                        )}
                                        {driverGps && (
                                            <Marker key={driverGps.latitude} position={{ lat: driverGps.latitude, lng: driverGps.longitude }} zIndex={1000000000} icon={{
                                                url: car,
                                                scaledSize: size,
                                                anchor: point,
                                                rotation: driverGps.heading
                                            }} visible={true} />
                                        )}
                                        {pickupPlace && <Marker position={pickupPlace} />}
                                        {dropoffPlace && <Marker position={dropoffPlace} />}
                                    </GoogleMap>
                                </div>
                            </div>
                        )}
                </div>
            </LoadScript>
        </>
    );
}