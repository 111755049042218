type TariffDetails = {
    startTime: string;
    endTime: string;
    days: string[];
    initialCharge: {
        distance: number;
        time: number;
        rate: number;
    };
    subsequentCharge: {
        distance: number;
        time: number;
        rate: number;
        maxDistance: number;
    };
    overMaxDistanceCharge: {
        distance: number;
        time: number;
        rate: number;
    };
    perMileRate: number;
    maxMiles: number;
};

type TaxiTariffType = {
    tariff1: TariffDetails;
    tariff2: TariffDetails;
    tariff3: TariffDetails;
    tariff4: {
        perMileRate: number;
    };
};

export function DRIVER8_DISTANCE_CHARGE(ukCurrentHour: string, ukCurrentDay: string, distance: number): number {
    // https://tfl.gov.uk/modes/taxis-and-minicabs/taxi-fares/tariffs

    const taxiTariff = {
      tariff1: {
        startTime: "05:00",
        endTime: "20:00",
        days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        initialCharge: {
          distance: 190.8,
          time: 41.0,
          rate: 380
        },
        subsequentCharge: {
          distance: 95.4,
          time: 20.5,
          rate: 20,
          maxDistance: 9635.4
        },
        overMaxDistanceCharge: {
          distance: 86.9,
          time: 18.7,
          rate: 20
        },
        perMileRate: 337,
        maxMiles: 6
      },
      tariff2: {
        startTime: "20:00",
        endTime: "22:00",
        days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        initialCharge: {
          distance: 156.6,
          time: 33.6,
          rate: 380
        },
        subsequentCharge: {
          distance: 78.3,
          time: 16.8,
          rate: 20,
          maxDistance: 9630.9
        },
        overMaxDistanceCharge: {
          distance: 86.9,
          time: 18.7,
          rate: 20
        },
        perMileRate: 411,
        maxMiles: 6
      },
      tariff3: {
        startTime: "22:00",
        endTime: "05:00",
        days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "PublicHoliday"],
        initialCharge: {
          distance: 148.0,
          time: 31.8,
          rate: 380
        },
        subsequentCharge: {
          distance: 74.0,
          time: 15.9,
          rate: 20,
          maxDistance: 9620.0
        },
        overMaxDistanceCharge: {
          distance: 86.9,
          time: 18.7,
          rate: 20
        },
        perMileRate: 435,
        maxMiles: 6
      },
      tariff4: {
        perMileRate: 370
      }
    };

    // Initialize current tariff as null
    let currentTariff: TariffDetails | null = null;
    
    // Loop through the taxi tariffs to determine the applicable tariff for the current hour and day
    for (const key in taxiTariff) {
        const details: TariffDetails = taxiTariff[key as keyof TaxiTariffType] as TariffDetails;
        if (details.days.includes(ukCurrentDay) && ukCurrentHour >= details.startTime && ukCurrentHour < details.endTime) {
            currentTariff = details;
            break;
        }
    }

    // Return a charge of 0 if no tariff is applicable
    if (!currentTariff) {
        return 0;
    }

    // If distance is more than 6 miles, use tariff4 rate for the entire journey
    if (distance > currentTariff.maxMiles * 1609.34) {
        return taxiTariff.tariff4.perMileRate * (distance / 1609.34);
    }

    // Initialize the charge with the initial charge based on the distance
    let charge = 0.0;
    if (distance <= currentTariff.initialCharge.distance) {
        charge += currentTariff.initialCharge.rate;
        distance = 0;
    } else {
        charge += currentTariff.initialCharge.rate;
        distance -= currentTariff.initialCharge.distance;
    }

    // Calculate the charge for the remaining distance based on tariff rules
    while (distance > 0) {
        if (distance <= currentTariff.subsequentCharge.distance) {
            charge += currentTariff.subsequentCharge.rate;
            distance = 0;
        } else if (distance < currentTariff.subsequentCharge.maxDistance) {
            charge += currentTariff.subsequentCharge.rate;
            distance -= currentTariff.subsequentCharge.distance;
        } else {
            charge += currentTariff.overMaxDistanceCharge.rate;
            distance -= currentTariff.overMaxDistanceCharge.distance;
        }
    }

    return charge;
  }